<template>
  <en-drawer
    :model-value="modelValue"
    :title="`${form.data.id ? '编辑' : '新建'}配方`"
    @close="$emit('update:model-value', false)"
    direction="btt"
    size="80%"
  >
    <en-form :model="form.data" :rules="form.rules" :ref="setRef('form')" class="grid grid-cols-4 gap-x-6">
      <en-form-item label="调色网点">
        <en-input :value="form.data.tenant?.shortName" disabled></en-input>
      </en-form-item>
      <en-form-item label="调色大师">
        <en-input :value="form.data.preparedBy" disabled></en-input>
      </en-form-item>
      <en-form-item label="汽车品牌" prop="brand">
        <en-input v-model="form.data.brand"></en-input>
      </en-form-item>
      <en-form-item label="车型" prop="vehicleSpec">
        <cascader-maintain
          v-model="form.data.vehicleSpec"
          :ajax="{ action: 'GET /enocloud/common/vehicle/brand', params: (params) => (params.payload = { depth: 2 }) }"
          :props="{ label: 'name', value: 'name', children: 'models' }"
          allow-create
          class="w-full"
        ></cascader-maintain>
      </en-form-item>
      <en-form-item label="年份" prop="startYear">
        <en-date-picker v-model="form.data.startYear" :disabled="form.disabled" class="w-full"></en-date-picker>
      </en-form-item>
      <en-form-item label="颜色代码" prop="colorCode">
        <en-input v-model="form.data.colorCode" :disabled="form.disabled"></en-input>
      </en-form-item>
      <en-form-item label="颜色名称" prop="colorName">
        <en-input v-model="form.data.colorName" :disabled="form.disabled"></en-input>
      </en-form-item>
      <en-form-item label="色系">
        <en-input
          :model-value="(form.data.colorFamilies as EnospraySprayDefinitions['LookupDto'][])?.map((item) => item.message).join(',')"
          disabled
        ></en-input>
      </en-form-item>
      <en-form-item label="颜色效果" prop="colorEffect.code">
        <select-maintain
          v-model="form.data.colorEffect"
          :ajax="{ action: 'GET /enospray/common/lookup/:lookupType', params: (params) => (params.paths = ['CLREFFECT']) }"
          :props="{ label: 'message', value: '' }"
          value-key="code"
          class="w-full"
        ></select-maintain>
      </en-form-item>
      <en-form-item label="油漆品牌" prop="paintType.code">
        <en-input :value="form.data.paintType?.message" disabled></en-input>
      </en-form-item>
      <en-form-item label="工序">
        <en-input :model-value="form.data.procedureType?.message" disabled></en-input>
      </en-form-item>
      <en-form-item label="差异色">
        <en-input v-model="form.data.diffCode"></en-input>
      </en-form-item>
      <en-form-item label="灰度" prop="grayLevel.code">
        <select-maintain
          v-model="form.data.grayLevel"
          :ajax="{ action: 'GET /enospray/common/lookup/:lookupType', params: (params) => (params.paths = ['GRAYLEVEL']) }"
          :props="{ label: 'message', value: '' }"
          value-key="code"
          class="w-full"
        ></select-maintain>
      </en-form-item>
      <en-form-item label="工艺" prop="craftType.code">
        <select-maintain
          v-model="form.data.craftType"
          :ajax="{ action: 'GET /enospray/common/lookup/:lookupType', params: (params) => (params.paths = ['CRAFTTYPE']) }"
          :props="{ label: 'message', value: '' }"
          value-key="code"
          class="w-full"
        ></select-maintain>
      </en-form-item>
      <en-form-item label="预计总用量">
        <en-input-number v-model="form.planWeight.value" class="w-full" @change="form.planWeight.change"></en-input-number>
      </en-form-item>
      <en-form-item label="备注">
        <en-input v-model="form.data.comment" type="textarea" :disabled="form.disabled"></en-input>
      </en-form-item>
      <en-form-item v-if="form.data.status?.code !== 'FA'">
        <span style="color: #f7ba2a"> 此配方仅供参考，望调色师傅们谨慎使用 </span>
      </en-form-item>
      <en-form-item label="照片上传" class="col-span-3">
        <upload-maintain v-model="form.data.imgUrls" multiple :limit="5" dir="formula"></upload-maintain>
      </en-form-item>
    </en-form>

    <div class="flex gap-6">
      <div class="flex-1">
        <div>
          <span>色漆层</span>
          <service-formula-paint-table :data="form.data.colors" :formula="form.data" :weight="form.planWeight.value"></service-formula-paint-table>
          <en-button v-if="!form.data.id" type="primary" text @click="form.colors.add.click">添加色母</en-button>
        </div>

        <div>
          <span>珍珠层</span>
          <service-formula-paint-table :data="form.data.pearls" :formula="form.data" :weight="form.planWeight.value"></service-formula-paint-table>
          <en-button v-if="!form.data.id" type="primary" text @click="form.pearls.add.click">添加色母</en-button>
        </div>
      </div>

      <service-formula-comment :data="form.data"></service-formula-comment>
    </div>

    <template #footer>
      <en-button @click="footer.cancel.click">取消</en-button>
      <en-button v-if="showPrintBtn && form.data.id" type="primary" @click="footer.print.click" class="button-margin-left">打印</en-button>
      <button-delete
        v-if="
          showDiscardBtn &&
          form.data.id &&
          store.accessRightsHash.FORMULA_DISCARD_ENOCH &&
          form.data?.status?.code &&
          ['NA', 'P', 'PA', 'FA'].includes(form.data?.status.code)
        "
        :link="false"
        :method="footer.discard.click"
        content="确定废弃吗？"
      >
        废弃
      </button-delete>
      <button-ajax
        v-if="showPreliminaryAuditBtn && form.data.id && form.data?.status?.code && ['P'].includes(form.data?.status.code)"
        :method="footer.preliminaryaudit.click"
      >
        首次验证
      </button-ajax>
      <button-ajax
        v-if="showAuditBtn && form.data.id && form.data?.status?.code && ['P', 'PA'].includes(form.data?.status.code)"
        :method="footer.audit.click"
      >
        最终验证
      </button-ajax>
      <button-ajax :disabled="!store.accessRightsHash.FORMULA_AUDIT_ENOCH" :method="footer.confirm.click">确定</button-ajax>
    </template>
  </en-drawer>
</template>

<script lang="ts">
import ServiceFormulaComment from '@spray/components/service-formula-comment.vue'
import ServiceFormulaPaintTable from '@spray/components/service-formula-paint-table.vue'

export default factory({
  components: { ServiceFormulaComment, ServiceFormulaPaintTable },

  props: {
    modelValue: Boolean as PropType<boolean>,
    data: Object as PropType<EnospraySprayDefinitions['SprayFormulaDto']>,
    showPrintBtn: Boolean as PropType<boolean>,
    showDiscardBtn: Boolean as PropType<boolean>,
    showPreliminaryAuditBtn: Boolean as PropType<boolean>,
    showAuditBtn: Boolean as PropType<boolean>
  },

  emits: {
    'update:model-value': (value: boolean) => typeof value === 'boolean',
    confirm: () => true
  },

  watch: {
    modelValue: {
      async handler() {
        this.form.init()
        if (this.modelValue) {
          this.form.data.id = this.data?.id
          if (this.form.data.id) this.form.get()
          else Object.assign(this.form.data, this.data)
        }
      }
    }
  },

  config: {
    children: {
      form: {
        ajax: {
          get: {
            action: 'GET /enospray/formula/:formulaId',
            data: 'object',
            loading: true,
            init: true,
            params(params) {
              params.paths = [this.form.data.id]
            }
          },
          submit: {
            action: 'POST /enospray/formula/:auditCode',
            loading: true,
            validate: true,
            params(params) {
              params.paths = [this.form.data.status?.code]
              params.payload = this.form.data
            }
          },
          update: {
            action: 'PUT /enospray/formula',
            loading: true,
            params(params) {
              params.payload = this.form.data
            }
          },
          discard: {
            action: 'POST /enospray/formula/:formulaId/discard',
            loading: true,
            params(params) {
              params.paths = [this.form.data.id]
            }
          },
          preliminaryaudit: {
            action: 'POST /enospray/formula/:formulaId/preliminaryaudit',
            loading: true,
            params(params) {
              params.paths = [this.form.data.id]
            }
          },
          audit: {
            action: 'POST /enospray/formula/:formulaId/audit',
            loading: true,
            params(params) {
              params.paths = [this.form.data.id]
            }
          }
        },
        rules: {
          brand: [{ required: true, message: '请填写车品牌' }],
          vehicleSpec: [{ required: true, message: '请选择车型' }],
          startYear: [{ required: true, message: '请选择年份' }],
          colorCode: [{ required: true, message: '请填写颜色代码' }],
          colorName: [{ required: true, message: '请填写颜色名称' }],
          'colorEffect.code': [{ required: true, message: '请选择颜色效果' }],
          'paintType.code': [{ required: true, message: '请选择油漆品牌' }],
          'grayLevel.code': [{ required: true, message: '请选择灰度' }],
          'craftType.code': [{ required: true, message: '请选择工艺' }]
        },
        computed: {
          disabled() {
            return !this.store.accessRightsHash.FORMULA_EDIT_ENOCH
          }
        },
        children: {
          planWeight: {
            value: 0,
            change(value: number) {}
          },
          colors: {
            add: {
              click() {
                this.form.data.colors ??= []
                this.form.data.colors.push({
                  goods: {} as EnospraySprayDefinitions['SprayGoodsDto'],
                  colorLayer: { code: 'P', type: '', description: '', message: '' },
                  weight: 0
                })
              }
            }
          },
          pearls: {
            add: {
              click() {
                this.form.data.pearls ??= []
                this.form.data.pearls.push({
                  goods: {} as EnospraySprayDefinitions['SprayGoodsDto'],
                  colorLayer: { code: 'P', type: '', description: '', message: '' },
                  weight: 0
                })
              }
            }
          }
        }
      },
      footer: {
        cancel: {
          click() {
            this.emit('update:model-value', false)
          }
        },
        preliminaryaudit: {
          async click() {
            await this.form.preliminaryaudit()
            return this.form.get()
          }
        },
        audit: {
          async click() {
            await this.form.audit()
            return this.form.get()
          }
        },
        confirm: {
          async click() {
            await this.form[this.form.data.id ? 'update' : 'submit']()
            this.emit('update:model-value', false)
            this.emit('confirm')
          }
        },
        discard: {
          click() {}
        },
        print: {
          click() {}
        }
      }
    }
  }
})
</script>
